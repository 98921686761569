export const removeChainSuffix = (str: string) => {
  return str.replace(/\(チェーン\)/, '')
}

export const escapeHTML = (str: string) => {
  const ESCAPE_MAP: { [key: string]: string } = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '`': '&#x60;',
  };
  return String(str).replace(/[&<>"'`]/g, function(match) {
    return ESCAPE_MAP[match];
  });
}
